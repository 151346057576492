import axios from 'axios'
axios.defaults.timeout = 15000
process.env.NODE_ENV === 'development' ? axios.defaults.baseURL = '/api' : axios.defaults.baseURL = 'https://chinapavilion.com.cn/'
axios.interceptors.request.use(config => {
  return config
}, error => {
  console.log(error)
})
axios.interceptors.response.use(res => {
  // console.log("aa", res)
  if (res.config.url === "/zglhztzxptglht.php/index/qiniueditor/qiniu.html") {
    return res.data
  }
  if (res.data.code == 1) {
    return res.data
  }
  return Promise.reject(res.data)
}, error => {
  alert('服务器错误，请联系管理员！')
  console.log(error)
});

export default axios
