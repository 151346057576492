import axios from './http'

const lang = localStorage.getItem('Lang') ? localStorage.getItem('Lang') === 'en' ? 2 : 1 : 1
export const allType = (data) => {
  return axios.get('/index/meizi/config', {
    params: {
      language: lang
    }
  })
}

export const getProgramsApi = (data) => {
  return axios.post('/index/meizi/list', {
    language: lang,
    ...data
  })
}

export const getDetailApi = (data) => {
  return axios.post('/index/meizi/detail', {
    language: lang,
    ...data
  })
}

export const searchApi = (data) => {
  return axios.post('/index/meizi/search', {
    language: lang,
    ...data
  })
}

export const getBannerApi = (data) => {
  return axios.post('/index/meizi/banner_list', {
    language: lang,
    ...data
  })
}

export const hotWordsApi = (data) => {
  return axios.post('/index/meizi/show_keywords', {
    language: lang,
    ...data
  })
}

export const loginApi = (data) => {
  return axios.post('/index/meizi/login', data)
}

export const registerApi = (data) => {
  return axios.post('/index/meizi/register', data)
}

export const getQiniuApi = () => {
  return axios.get('/zglhztzxptglht.php/index/qiniueditor/qiniu.html')
}

export const getCodeApi = (data) => {
  return axios.post('/index/meizi/email', data)
}

export const toUserinfo = (data) => {
  return axios.post('/index/meizi/jump_center', data)
}

export const addDownLog = (data) => {
  return axios.post('/index/meizi/add_down_log', data)
}

export const appDownload = (data) => {
  return axios.post('index/meizi/apply_download', data)
}

export const outLogin = (data) => {
  return axios.post('index/meizi/logout', data)
}
export const forget = (data) => {
  return axios.post('index/user/forget', data)
}
